<template>
  <div :class="classes">
    <VIcon
      v-if="isSelected"
      color="success"
      class="message__icon"
    >
      done
    </VIcon>
    <div
      class="message__inner"
      @click="$emit('selectMessage', message.id)"
    >
      <header
        v-if="message.sender_title"
        class="title"
      >
        {{ message.sender_title }}
      </header>
      <div class="message__content">
        <MarkdownContent :content="message.body" />
        <p v-if="message.file_name">
          <a
            :href="`/${message.file_path}`"
            target="_blank"
          >
            {{ message.file_name }}
          </a>
        </p>
      </div>
      <footer class="message__footer">
        <time>{{ createdAt }}</time>
      </footer>
    </div>
  </div>
</template>

<script>
import { UDate } from '@/utils/date'
import MarkdownContent from '@/components/MarkdownContent.vue'

export default {
  name: 'MessageListItem',
  components: { MarkdownContent },

  props: {
    message: {
      type: Object,
      required: true
    },

    currentMessageID: {
      type: Number,
      default: 0
    }
  },

  computed: {
    classes () {
      return {
        message: true,
        'message--outgoing': this.message.is_sender,
        'message--incoming': !this.message.is_sender,
        'message--selected': this.isSelected
      }
    },

    createdAt () {
      return UDate.formatDateTime(this.message.created_at)
    },

    isSelected () {
      return this.message.id === this.currentMessageID
    }
  }
}
</script>

<style lang="scss" scoped>
$icon-margin: 1rem;

.message {
  margin: 0.5rem 1rem;
  display: flex;

  p {
    margin-bottom: $spacer;
  }
}

.message__inner {
  padding: 0.5rem 1rem;
  min-width: 15rem;
  background-color: gainsboro;
  max-width: 25rem;
  border-radius: $border-radius-root * 2;
}

.message__content {
  font-size: 0.875rem;
}

.message--outgoing {
  justify-content: flex-start;

  .message__icon {
    margin-right: $icon-margin;
    margin-left: 0;
  }

  .message__inner {
    background-color: gainsboro;
  }
}

.message--incoming {
  justify-content: flex-end;

  .message__icon {
    margin-left: $icon-margin;
    margin-right: 0;
  }

  .message__inner {
    background-color: lavender;
    order: -1;
  }

  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    justify-content: flex-start;

    .message__icon {
      margin-right: $icon-margin;
      margin-left: 0;
    }

    .message__inner {
      order: 1;
    }
  }
}

.message__footer {
  display: flex;
  justify-content: flex-end;
  font-size: 0.75rem;
}

</style>
