<template>
  <div
    ref="container"
    v-scroll.self="checkButtonIsShow"
    class="messages"
  >
    <MessageListItem
      v-for="message in filteredMessages"
      :key="message.id"
      :message="message"
      :current-message-i-d="currentMessageID"
      @selectMessage="$emit('selectMessage', $event)"
    />

    <VFabTransition>
      <VBtn
        v-show="isShowButton"
        color="primary"
        class="messages__scroll-button"
        fab
        small
        @click="toScroll()"
      >
        <VIcon>keyboard_arrow_down</VIcon>
      </VBtn>
    </VFabTransition>
  </div>
</template>

<script>
import MessageListItem from '@components/MessageListItem.vue'

export default {
  name: 'MessageList',
  components: {
    MessageListItem
  },

  props: {
    messages: {
      type: Array,
      required: true
    },

    currentMessageID: {
      type: Number,
      default: 0
    }
  },

  data () {
    return {
      isShowButton: false
    }
  },

  computed: {
    filteredMessages () {
      return this.messages.filter(message => !message.is_deleted)
    },

    countMessages () {
      return this.messages.length
    }
  },

  watch: {
    countMessages (val) {
      // Последнее сообщение исходящее прокрутим список сообщений к нему
      if (this.messages[val - 1]?.is_sender) {
        setTimeout(() => this.toScroll())
      }

      this.checkButtonIsShow()
    }
  },

  mounted () {
    this.toScroll()
    this.$emit('read')
  },

  methods: {
    checkButtonIsShow () {
      const container = this.$refs.container
      if (!container) { return }

      const offset = (container.offsetHeight || 0) + 32
      const maxScrollTop = (container.scrollHeight || 0)
      const minPositionTop = (container.scrollTop || 0) + offset
      if (minPositionTop < maxScrollTop) {
        this.isShowButton = true
        return
      }

      this.isShowButton = false
    },

    toScroll () {
      const container = this.$refs.container
      this.$vuetify.goTo(container.scrollHeight || 0, {
        container,
        duration: 0
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .messages {
    overflow: auto;
    height: 100%;
  }

  .messages__scroll-button {
    position: absolute;
    top: auto;
    bottom: 0;
    right: 0;
    left: auto;
    margin: 1rem;
  }
</style>
